import { useApi } from "lib/apiFetcher";

export const useMyClassApi = () => useApi("get", "/profile/my-class");
export const useMyClassTransactionApi = () => useApi("get", "/transactions");
export const useCreateTransactionApi = () => useApi("post", "/transactions");

export const useMyClassTransactionDetailApi = (param?: string) =>
  // @ts-ignore
  useApi("get", `/transactions/{id}${param ? param : ""}`);

export const useProcessTransaction = () =>
  useApi("post", "/transactions/process");

// error path
export const usePostPromoCode = () =>
  //@ts-ignore
  useApi("post", `/transactions/{id}/promocode`);
export const useDeletePromoCode = (additionalKey: string) =>
  //@ts-ignore
  useApi("delete", `/transactions/${additionalKey}/promocode`);

//@ts-ignore
export const useGenerateCertificate = (additionalKey: string | number) =>
  //@ts-ignore
  useApi("post", `/courses/${additionalKey}/certificate`);

export const useDownloadCertificate = (additionalKey: string | number) =>
  //@ts-ignore
  useApi("get", `/courses/${additionalKey}/download-certificate`);

export const useStreamCertificate = (additionalKey: string | number) =>
  //@ts-ignore
  useApi("get", `/courses/${additionalKey}/stream-certificate`);
