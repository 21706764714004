export const SECTION_GERBANGSARI = [
  "Selamat datang di Gerbangsari goKampus",
  "Pertanian",
  "Personal Development",
  "Customer Excellence",
  "Product Design & Management",
  "Pengusaha",
  "Penjualan"
];

export const SECTION_MALUKU = [
  // it can be adjust on zeus
  // "UMKM",
  // "Marketing & Branding",
  "Personal Development"
];

export const SECTION_PLUS = [
  {
    id: "1",
    title: `sectionTech`,
    tag: "Digital & IT",
    sort: null,
    emoji: "💻"
  },
  {
    id: "99",
    title: `sectionNew`,
    tag: "newest",
    sort: "created_date",
    emoji: `🆕`
  },
  {
    id: "3",
    title: `sectionManagement`,
    tag: "Personal Development",
    sort: null,
    emoji: "⏰"
  },
];

export const SECTION_BASIC = [
  {
    id: "1",
    title: `sectionWork`,
    tag: "Performance & Productivity",
    sort: null,
    emoji: "🧑‍💼"
  },
  {
    id: "2",
    title: `sectionTech`,
    tag: "Digital & IT",
    sort: null,
    emoji: "💻"
  },
  {
    id: "3",
    title: `sectionManagement`,
    tag: "Personal Development",
    sort: null,
    emoji: "⏰"
  },
];

export const SECTION_PRO = [
  {
    id: "1",
    title: `sectionWork`,
    tag: "Performance & Productivity",
    sort: null,
    emoji: "🧑‍💼"
  },
  {
    id: "2",
    title: `sectionManagement`,
    tag: "Personal Development",
    sort: null,
    emoji: "⏰"
  },
  {
    id: "3",
    title: `sectionTech`,
    tag: "Digital & IT",
    sort: null,
    emoji: "💻"
  },
];

export const SECTION_MANDATORY = [
  {
    id: "1",
    title: `mandatoryCourses`,
    tag: null,
    sort: null,
    emoji: null
  }
];

export const mapArrayToSection = (inputArray) => {
  return inputArray.map((item, index) => {
    return {
      id: `${SECTION_PRO.length + index + 1}`, // Generate a unique id
      title: `section${item.replace(/\s/g, '')}`, // Generate a title based on the item
      tag: item,
      sort: null,
      emoji: null // You can set a default emoji or choose based on your requirements
    };
  });
};

export const mapTagsToSection = (listTags) => {
  return listTags.map((el, idx) => {
    return {
      id: idx,
      title: null,
      tag: el,
      sort: null,
      emoji: null
    }
  })
};