import Button from "components/Button";
import { useUserPreference } from "modules/User/userApi";

export const KYC = ({ selectedKYCTags, competencesKYC, handleTagClick, submitPreference, isLoading }) => {
  const disableKYCSubmitBtn = isLoading ? "loading" : selectedKYCTags.length === 0 ? "disabled" : "active"
  
  return (
    <>
      <div className="max-h-[250px] overflow-auto">
        {competencesKYC?.map((c, competenceIndex) => (
          <>
            <div
              className="flex gap-12 px-24 items-center font-light cursor-pointer"
              key={c + Math.random()}
              >
              <h3 className="text-b2 text-neutral-60 font-medium mb-8">
                {c.name + " (optional)"}
              </h3>
            </div>

            <div className="flex flex-wrap px-16 mb-12">
              {c.tags
                // .slice(0, displayMoreTags[competenceIndex] ? c.tags.length : 3)
                .map((t, tagIndex) => {
                  const info = {
                    parent: c.name,
                    tag: t.name,
                  };

                  const position = selectedKYCTags.indexOf(t.name) + 1
                  return (
                    <div
                    className="flex items-center font-light cursor-pointer"
                    key={t + Math.random()}
                    onClick={() => handleTagClick(competenceIndex, tagIndex, info)}
                    >
                      <div className={`text-c1 m-4
                      font-medium px-12 py-8 border-2  line-clamp-2 rounded-full 
                      ${t.selected ? "bg-purple-50 text-white border-purple-50":
                      "text-neutral-80 border-neutral20_to_dark30"} flex items-center`
                      }>
                        {
                          t.selected && (
                            <div className="rounded-[50%] w-16 h-16 mr-4 inline-block bg-white text-purple-50">
                              <p className="flex items-center justify-center">{position}</p>
                            </div>
                          )
                        }
                        <p className="inline-block">{t.name}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        ))}

      </div>
      <div className="p-24">
        <Button isFull state={disableKYCSubmitBtn} onClick={() => submitPreference(false)}>
          <p>({selectedKYCTags.length}/3) Simpan</p>
        </Button>
      </div>
    </>
  )
};
