import { useEffect, useRef, useCallback } from 'react';

const useInfiniteScroll = (loadMore, hasMore, isLoader) => {
  const observer = useRef(null);

  const lastCourseElm = useCallback(
    (node) => {
      if (isLoader || !hasMore) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoader, hasMore, loadMore]
  );

  return lastCourseElm;
};

export default useInfiniteScroll;
