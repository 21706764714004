import { useSystemStore } from "components/PageHeader/systemStore";
import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export const IconCheckboxActive: React.FC<Props> = ({
  size = 20,
  color = "#318A4E",
}) => {
  const theme = useSystemStore((state) => state.theme);

  if (theme == "dark") {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2" width="20" height="20" rx="7" fill="#D9D9D9" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 2H17C18.3261 2 19.5979 2.52678 20.5355 3.46447C21.4732 4.40215 22 5.67392 22 7V17C22 18.3261 21.4732 19.5979 20.5355 20.5355C19.5979 21.4732 18.3261 22 17 22H7C5.67392 22 4.40215 21.4732 3.46447 20.5355C2.52678 19.5979 2 18.3261 2 17V7C2 5.67392 2.52678 4.40215 3.46447 3.46447C4.40215 2.52678 5.67392 2 7 2ZM18.1718 9.66763C18.6094 9.21751 18.6094 8.48772 18.1718 8.03759C17.7341 7.58747 17.0245 7.58747 16.5869 8.03759L10.5395 14.2572C10.3573 14.4446 10.0565 14.4446 9.87427 14.2572L7.41314 11.726C6.97548 11.2758 6.2659 11.2758 5.82824 11.726C5.39059 12.1761 5.39059 12.9059 5.82824 13.356L8.78049 16.3923C9.56827 17.2026 10.8455 17.2026 11.6333 16.3923L18.1718 9.66763Z"
          fill="#A184DD"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2" width="20" height="20" rx="7" fill="#D9D9D9" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 2H17C18.3261 2 19.5979 2.52678 20.5355 3.46447C21.4732 4.40215 22 5.67392 22 7V17C22 18.3261 21.4732 19.5979 20.5355 20.5355C19.5979 21.4732 18.3261 22 17 22H7C5.67392 22 4.40215 21.4732 3.46447 20.5355C2.52678 19.5979 2 18.3261 2 17V7C2 5.67392 2.52678 4.40215 3.46447 3.46447C4.40215 2.52678 5.67392 2 7 2ZM18.1718 9.66763C18.6094 9.21751 18.6094 8.48772 18.1718 8.03759C17.7341 7.58747 17.0245 7.58747 16.5869 8.03759L10.5395 14.2572C10.3573 14.4446 10.0565 14.4446 9.87427 14.2572L7.41314 11.726C6.97548 11.2758 6.2659 11.2758 5.82824 11.726C5.39059 12.1761 5.39059 12.9059 5.82824 13.356L8.78049 16.3923C9.56827 17.2026 10.8455 17.2026 11.6333 16.3923L18.1718 9.66763Z"
          fill="#551FC1"
        />
      </svg>
    );
  }
};
