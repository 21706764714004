import { ArrayObjectSortBy } from "lib/object";
import createStore from "zustand";

export const courseTabItems = [
  "Konten Kelas",
  "Overview",
  "Rekomendasi Kursus Lainnya",
];

type CourseTabItem = (typeof courseTabItems)[number];

export type Lesson = {
  content_url?: string;
  description?: string;
  duration?: string;
  is_free?: boolean;
  name?: string;
  slug?: string;
  sort_order?: number;
  type?:
    | "Video"
    | "Quiz"
    | "SCORM"
    | "IFrame"
    | "Webinar"
    | "PDF"
    | "Assignment"
    | "Article"
    | "AWS";
  webinar?: {
    id?: number;
    webinar_url?: string;
  };
  by_ai?: any;
};

export type CourseAI = {
  id?: number;
  assignment_question?: string;
  assignment_answer?: string;
  passing_grade?: number;
  user_answer?: {
    id?: number;
    user_answer?: string;
    score?: number;
    feedback?: string;
  };
};

export type Course = {
  slug?: string;
  name?: string;
  description?: string;
  image_cover_url?: string;
  video_trailer_url?: string;
  duration?: number;
  has_certificate?: boolean;
  is_paywall_enabled?: boolean;
  institution?: {
    slug?: string;
    name?: string;
    description?: string;
    image_url?: string;
    is_business?: boolean;
  };
  partnership?: {
    slug?: string;
    name?: string;
    image_url?: string;
  };
  skills?: {
    slug?: string;
    name?: string;
  }[];
  chapters?: {
    number?: number;
    name?: string;
    chapter_lesson_count?: number;
    chapter_lesson_done?: number;
    lessons?: {
      id?: number;
      sort_order?: number;
      slug?: string;
      name?: string;
      description?: string;
      type?: string;
      duration?: number;
      is_free?: boolean;
      is_premium?: boolean;
    }[];
  }[];
  total_reviews?: number;
  review_summary?: {
    star_avg?: number;
    star_5?: number;
    star_4?: number;
    star_3?: number;
    star_2?: number;
    star_1?: number;
  };
  total_learners?: number;
};

export type CourseStatus = Course & {
  course_completion_rate?: number;
  course_last_lesson_slug?: string;
  course_lesson_count?: number;
  course_lesson_done?: number;
  is_paid?: boolean;
  id?: number;
  image_cover_url?: string;
  chapters?: {
    number: number;
    name: string;
    lessons?: {
      slug: string;
      progress: {
        course_lesson_progress_slug: string;
        created_at: string;
        id: number;
        is_active: boolean;
        is_done: boolean;
        updated_at: string;
      }[];
    }[];
  }[];
};

export type Bundle = {
  title: string;
  slug: string;
  description: string;
  benefits: {
    desc: string;
    title: string;
  }[];
  features: string[];
  discount: number;
  price: number;
  certificate_sample_image: string;
  first_course_slug: string;
  first_course_name: string;
  institutions: {
    name: string;
    slug: string;
    image_url: string;
  }[];
  courses: {
    id: number;
    slug: string;
    name: string;
    description: string;
    image_cover_url: string;
    video_trailer_url: string;
    bootcamp_image_url: string;
    duration: number;
    has_certificate: number;
    original_price: number;
    discounted_price: number;
    bundle_price: number;
    total_learners: number;
  }[];
  total_learners_bundle: number;
};

export type CourseStore = {
  // state
  currentTab: CourseTabItem;
  isLessonLoading: boolean;
  currentLesson: Lesson | null;
  isPaywallShown: boolean;
  isLock: boolean;
  courseStatus: CourseStatus | null;
  courseEnrollmentId: number | null;
  currentBundle: Bundle | null;
  allCourse: Course[] | null;
  currentCourse: Course | null;
  showBundlePaywall: boolean;
  showSuccesBundle: boolean;
  showModalReview: boolean;
  bundleEnrollmentId: string;
  mandatoryCount: number | null;
  showModalSubscribeBusiness: boolean;
  showModalSubscribeBusinessHR: boolean;
  showModalQuiz: boolean;

  // actions
  setCurrentTab: (tab: CourseTabItem | any) => void;
  setPaywallShown: (b: boolean) => void;
  setLock: (b: boolean) => void;
  setShowBundlePaywall: (b: boolean) => void;
  setShowSuccesBundle: (b: boolean) => void;
  setShowModalReview: (b: boolean) => void;
  setLessonLoading: (isLoading?: boolean) => void;
  setCurrentLesson: (lesson?: Lesson) => void;
  setAllCourse: (course?: Course[]) => void;
  setCurrentCourse: (course?: Course) => void;
  setCourseStatus: (courseStatus?: CourseStatus) => void;
  setCourseEnrollmentId: (id?: number) => void;
  setBundleEnrollmentId: (id?: string) => void;
  setBundle: (bundle?: Bundle) => void;
  setMandatoryCount: (count?: number) => void;
  setShowModalSubscribeBusiness: (b: boolean) => void;
  setShowModalSubscribeBusinessHR: (b: boolean) => void;
  setShowModalQuiz: (b: boolean) => void;
};

export const useCourseStore = createStore<CourseStore>((set) => ({
  // state
  currentTab: courseTabItems[0],
  isLessonLoading: false,
  currentLesson: null,
  isPaywallShown: false,
  isLock: false,
  courseStatus: null,
  courseEnrollmentId: null,
  currentBundle: null,
  allCourse: null,
  currentCourse: null,
  showBundlePaywall: false,
  showSuccesBundle: false,
  showModalReview: false,
  bundleEnrollmentId: "",
  mandatoryCount: 0,
  showModalSubscribeBusiness: false,
  showModalSubscribeBusinessHR: false,
  showModalQuiz: false,

  // actions
  setCurrentTab(tab) {
    set({
      currentTab: tab,
    });
  },
  setPaywallShown(b) {
    set({
      isPaywallShown: b,
    });
  },
  setLock(b) {
    set({
      isLock: b,
    });
  },
  setLessonLoading(isLoading = false) {
    set({
      isLessonLoading: isLoading,
    });
  },
  setCurrentLesson(lesson) {
    set({
      currentLesson: lesson,
    });
  },
  setAllCourse(course) {
    set({
      allCourse: course,
    });
  },
  setCurrentCourse(course) {
    set({
      currentCourse: course,
    });
  },
  setCourseStatus(courseStatus) {
    set({
      courseStatus: courseStatus,
    });
  },
  setCourseEnrollmentId(id) {
    set({
      courseEnrollmentId: id,
    });
  },
  setBundleEnrollmentId(id) {
    set({
      bundleEnrollmentId: id,
    });
  },
  setBundle(currentBundle) {
    set({
      currentBundle,
    });
  },
  setShowBundlePaywall(showBundlePaywall) {
    set({
      showBundlePaywall,
    });
  },
  setShowSuccesBundle(showSuccesBundle) {
    set({
      showSuccesBundle,
    });
  },
  setShowModalReview(showModalReview) {
    set({
      showModalReview,
    });
  },
  setMandatoryCount(count) {
    set({
      mandatoryCount: count,
    });
  },
  setShowModalSubscribeBusiness(showModal) {
    set({
      showModalSubscribeBusiness: showModal,
    });
  },
  setShowModalSubscribeBusinessHR(showModal) {
    set({
      showModalSubscribeBusinessHR: showModal,
    });
  },
  setShowModalQuiz(showModal) {
    set({
      showModalQuiz: showModal,
    });
  },
}));

export const getChapter = (
  chapters: {
    number?: number;
    lessons?: Lesson[];
  }[],
  currentLessonSlug: string
) => {
  const chapter =
    chapters && chapters.length
      ? chapters.find((c) => {
          const l = c.lessons?.find((l) => l.slug == currentLessonSlug);
          return !!l;
        })
      : [];
  return chapter;
};

export const getNextLesson = (
  chapters: {
    number?: number;
    lessons?: Lesson[];
  }[],
  currentLessonSlug: string | null
) => {
  const sortedChapters =
    chapters && chapters.length
      ? ArrayObjectSortBy(chapters, "number").map((c) => {
          return {
            ...c,
            lessons: ArrayObjectSortBy(c.lessons, "sort_order"),
          };
        })
      : [];

  let sortedLessons: (Lesson & { chapter: number })[] = [];
  sortedChapters.forEach((c) => {
    c.lessons.forEach((l) => sortedLessons.push({ ...l, chapter: c.number }));
  });

  let nextLesson: Lesson | null = null;

  for (let index = 0; index < sortedLessons.length - 1; index++) {
    if (sortedLessons[index].slug == currentLessonSlug) {
      nextLesson = sortedLessons[index + 1];
      break;
    }
  }

  return nextLesson;
};

export const getPrevLesson = (
  chapters: {
    number?: number;
    lessons?: Lesson[];
  }[],
  currentLessonSlug: string | null
) => {
  const sortedChapters =
    chapters && chapters.length
      ? ArrayObjectSortBy(chapters, "number").map((c) => {
          return {
            ...c,
            lessons: ArrayObjectSortBy(c.lessons, "sort_order"),
          };
        })
      : [];

  let sortedLessons: (Lesson & { chapter: number })[] = [];
  sortedChapters.forEach((c) => {
    c.lessons.forEach((l) => sortedLessons.push({ ...l, chapter: c.number }));
  });

  let prevLesson: Lesson | null = null;

  for (let index = 1; index < sortedLessons.length; index++) {
    if (sortedLessons[index].slug == currentLessonSlug) {
      prevLesson = sortedLessons[index - 1];
      break;
    }
  }

  return prevLesson;
};

export const isHasWebinar = (
  chapters: {
    number?: number;
    lessons?: Lesson[];
  }[]
) => {
  return chapters.some((c) => c.lessons.some((l) => l.type === "Webinar"));
};

export const getFirstUnfinishedLesson = (courseStatus: CourseStatus) => {
  const sortedChapters =
    courseStatus.chapters && courseStatus.chapters.length
      ? ArrayObjectSortBy(courseStatus.chapters, "number").map((c) => {
          return {
            ...c,
            lessons: ArrayObjectSortBy(c.lessons, "sort_order"),
          };
        })
      : [];

  let sortedLessons: {
    slug: string;
    progress: {
      course_lesson_progress_slug: string;
      created_at: string;
      id: number;
      is_active: boolean;
      is_done: boolean;
      updated_at: string;
    }[];
  }[] = [];

  sortedChapters.forEach((c) => {
    c.lessons.forEach((l) => sortedLessons.push({ ...l }));
  });

  const unfinishedLesson = sortedLessons.find(
    (l) => l.progress[0]?.is_done == false
  );

  return unfinishedLesson;
};

// get lesson number from slug name
export const getLessonNumberBySlug = (
  slug: string,
  courseStatus: CourseStatus
) => {
  let allArray = [];
  if (courseStatus && courseStatus.chapters && courseStatus.chapters.length) {
    courseStatus.chapters.forEach((el) => {
      el.lessons.forEach((a, i) => {
        allArray.push({
          number: el.number,
          slug: a.slug,
        });
      });
    });
  }
  if (allArray.length) {
    allArray = [...allArray].map((a, idx) => {
      return {
        ...a,
        lesson_num: idx + 1,
      };
    });
  }

  return allArray.find((a) => a.slug == slug)?.lesson_num;
};

export const getLessonIdBySlug = (chapters = [], slug: string) => {
  // Iterate through the chapters array
  for (const chapter of chapters) {
    // Iterate through the lessons in each chapter
    for (const lesson of chapter.lessons) {
      // Check if the lesson's slug matches the given slug
      if (lesson.slug === slug) {
        // Return the ID of the matching lesson
        return {
          lessonId: lesson.id,
          chapterNum: chapter.number,
        };
      }
    }
  }
  // If no matching lesson is found, return null or an appropriate value
  return null;
};
