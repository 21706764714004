import React, { useState, useEffect } from 'react';

function shallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (let key of keysA) {
    if (objA[key] !== objB[key]) {
      return false;
    }
  }

  return true;
}

export function useStateWithMemo(initialValue) {
  const [state, setState] = useState(initialValue);

  const memoizedSetState = (newValue) => {
    if (!shallowEqual(newValue, state)) {
      setState(newValue);
    }
  };

  return [state, memoizedSetState];
}