import React, { useCallback, useEffect, useRef, useState } from "react";
import CardCourseWithLesson from "components/Card/CardCourseWithLesson";
import useTranslation from "next-translate/useTranslation";
import IconClose from "components/Icon/IconClose";

const HomeCardKeywords: React.FC<{
  data?: any;
  isKeywordsSearched?: string;
  handleReset?: any;
  isSquare?: boolean;
}> = ({ data, isKeywordsSearched, handleReset, isSquare }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <div className="pb-24 flex justify-start items-center px-16 md:px-0 gap-8 mt-16">
        <div
          className="font-light items-center flex"
          dangerouslySetInnerHTML={{
            __html: t("descriptionResultSearch", {
              count: (data && data.length) ?? 0,
              text: isKeywordsSearched,
              additionalS: data && data.length > 1 ? "s" : "",
            }),
          }}
        ></div>
        <div onClick={handleReset} className="cursor-pointer block">
          <IconClose size={20} color="#551FC1" />
        </div>
      </div>
      <div
        className={
          "grid-cols-3 xl-max:grid-cols-2 hg-max:!grid-cols-1 gap-20 justify-center grid"
        }
      >
        {data &&
          data.length &&
          data.map((course, index) => {
            let myRef = null;
            //   if (
            //     data.length === index + 1 &&
            //     currentUser &&
            //     currentUser?.referral !== "interagroup"
            //   ) {
            //     myRef = lastCourseElm;
            //   }

            return (
              <div ref={myRef} key={course.name + index}>
                <CardCourseWithLesson
                  {...course}
                  isSquare={isSquare}
                  tracker={{
                    event: "b2b_homepage_course_a",
                    property: {
                      course_name: course?.name,
                      course_slug: course?.slug,
                      institution_name: course?.institution?.name,
                      section: 3,
                      category_name: `Default`,
                      index,
                      is_mandatory_course: false,
                    },
                  }}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default HomeCardKeywords;
