import { CardTenantMyClass } from 'components/Card/CardTenantMyClass';
import { Course } from 'components/Card/interfaces';
import { trackEvent } from 'lib/amplitude';
import Link from 'next/link';

interface CardContinueProps {
  onGoingCourses: Course[];
}

const CardContinue: React.FC<CardContinueProps> = ({ onGoingCourses }) => {
  return (
    <>
      {
        onGoingCourses.length > 0 &&
        onGoingCourses.map((course, index) => (
          <Link
            href={{
              pathname: "/course/[courseSlug]",
              query: { courseSlug: course.slug },
            }}
            onClick={() => {
              trackEvent({
                event: "b2b_homepage_course_a",
                property: {
                  course_name: course?.name,
                  course_slug: course?.slug,
                  institution_name: course?.institution?.name,
                  remaining_time: (course as any)?.is_remaining_duration,
                  course_progress: (
                    (course?.enrollment_progress?.finished /
                      course?.enrollment_progress?.total) *
                    100
                  )
                    .toString()
                    .slice(0, 4),
                  section: 0,
                  category_name: `Continue Your Course`,
                  index,
                },
              });
            }}
          >
            <CardTenantMyClass {...course} height={132} isHomePage />
          </Link>
        ))
      }
    </>
  );
}

export default CardContinue