import CardWithVideo from "components/Card/CardWithVideo";
import { IconArrow } from "components/Icon/IconArrow";
import { IconArrowDown } from "components/Icon/IconArrowDown";
import { trackEvent } from "lib/amplitude";
import { useAuthStore } from "modules/Auth/authStore";
import {
  useGetCoursesByTenantSlug,
  useGetSectionDetail,
} from "modules/Tenant/homeTenantApi";
import Link from "next/link";
import React, { Suspense, useEffect, useRef, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import {
  SkeletonCircle,
  SkeletonRect,
  SkeletonWrapper,
} from "components/Skeleton/Skeleton";
import { useCourseStore } from "modules/Course/courseStore";
import { getSubDomain } from "lib/host";
// import CardCourseWithLesson from "components/Card/CardCourseWithLesson";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, FreeMode } from "swiper";
import { useModalSectionStore } from "./modalSectionStore";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useSearchStore } from "components/InstantSearchBox/searchStore";

const CardCourseWithLesson = dynamic(
  () =>
    import("components/Card/CardCourseWithLesson").then((mod) => mod.default),
  { ssr: false }
);

SwiperCore.use([Navigation, FreeMode]);
interface SectionProps {
  isPopular?: boolean;
  tag: string; // Change the type to match what your 'tag' prop should be
  title: string;
  emoji: string;
  section: number;
  sort?: string; // Change the type to match what your 'tag' prop should be
  data?: any[];
  isNeedFetchCourse?: boolean;
  isHidden?: any;
  id?: any;
}

const Section: React.FC<SectionProps> = ({
  isPopular = false,
  tag,
  title,
  section,
  sort = "",
  emoji,
  data,
  isNeedFetchCourse = true,
  isHidden = null,
  id,
  ...props
}) => {
  // @ts-ignore
  const getCourses = useGetCoursesByTenantSlug();
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useAuthStore((state) => state.currentUser);
  const [currData, setCurrData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(data?.length ?? 0);
  const [noMoreData, setNoMoreData] = useState(false);
  const [shuffledData, setShuffledData] = useState(null);

  const { t } = useTranslation("common");
  const { t: tr } = useTranslation("tags");
  const [setSelectedTitle, isShow, setIsShow, setDataSection, setLoading] =
    useModalSectionStore((state) => [
      state.setSelectedTitle,
      state.isShow,
      state.setIsShow,
      state.setDataSection,
      state.setLoading,
    ]);
  const { popularCourse } = useSearchStore();
  const [limit, setLimit] = useState(isPopular ? popularCourse?.length : 10);

  const { mandatoryCount, setMandatoryCount } = useCourseStore((s) => ({
    mandatoryCount: s.mandatoryCount,
    setMandatoryCount: s.setMandatoryCount,
  }));
  const getSectionDetail = useGetSectionDetail();

  useEffect(() => {
    if (isPopular) {
      setCurrData(popularCourse);
    } else {
      setCurrData(data ? data : []);
    }
    // console.log(currData);
  }, [data]);

  const fetchMyCourses = () => {
    /// Prevent API to fetch moodle subdomain since tenant isn't on the BE
    const noTenantSubdomain = ["moodle"];
    const currentSlug =
      currentUser?.referral ?? getSubDomain(window.location.hostname);
    if (!noTenantSubdomain.includes(currentSlug)) {
      getCourses.doFetch(
        sort
          ? {
              slug:
                window.location.hostname === "e-learning.bluebirdgroup.com"
                  ? "bluebird"
                  : currentSlug,
              limit,
              sort: sort ?? "",
              page,
              "tags[]": tag == "newest" ? null : tag,
            }
          : {
              slug:
                window.location.hostname === "e-learning.bluebirdgroup.com"
                  ? "bluebird"
                  : currentSlug,
              limit,
              page,
              "tags[]": tag == "newest" ? null : tag,
            },
        {
          onSuccess(data) {
            // if (sort) {
            // console.log(data);
            // }

            const uniqueData = data?.data.filter((item: any) => {
              return !currData.some(
                (existingItem) => existingItem.id === item.id
              );
            });

            // setCurrData((prev) => [...prev, ...uniqueData]);
            if (isPopular) {
              setCurrData(popularCourse);
            } else {
              setCurrData(data?.data);
            }
            // if (data.meta.current_page === data.meta.last_page) {
            //   setTotalData([...currData, ...uniqueData].length);
            // } else {
            //   setTotalData(data.meta.total);
            // }

            setTotalData((data as any).meta.total);

            const mandatories = data?.course_mandatories_count;
            // const mandatories = 0

            setMandatoryCount(mandatories);
            setIsLoading(false);
          },
          onError(e) {
            setTotalData(0);
            setIsLoading(false);
          },
        }
      );
    }
  };

  const fetchSectionDetail = () => {
    const currentSlug =
      currentUser?.referral ?? getSubDomain(window.location.hostname);
    if (typeof id === "number") {
      // section mandatory by id
      getSectionDetail.doFetch(
        {
          slug:
            window.location.hostname === "e-learning.bluebirdgroup.com"
              ? "bluebird"
              : currentUser?.referral,
          section: id,
        },
        {
          onSuccess(data: any) {
            setDataSection(data?.data[0].tag.courses);
            setLoading(false);
          },
        }
      );
    } else if (typeof id === "string" && id == "default" && currentSlug) {
      // section sorting by tag
      getCourses.doFetch(
        {
          slug:
            window.location.hostname === "e-learning.bluebirdgroup.com"
              ? "bluebird"
              : currentSlug,
          // @ts-ignore
          "tags[]": tag,
        },
        {
          onSuccess(data: any) {
            setDataSection(data?.data);
            setLoading(false);
          },
        }
      );
    } else if (!id) {
      // section kursus terbaru
      setDataSection(currData);
      setLoading(false);
    }
  };

  // console.log(currData);

  let itemCounts = page * limit;
  if (page > 1) itemCounts = totalData;
  const displayedItems = [...currData].slice(0, itemCounts);

  const renderCount = useRef(0);
  const loginState = useAuthStore((state) => state.loginState);
  // useEffect(() => {
  //   /// Nonaktif karena tidak jalan di kemenperin prod
  //   // renderCount.current = renderCount.current + 1;
  //   // if (renderCount.current === 2) {
  //   //   setLimit(9);
  //   //   return;
  //   // }
  //   // No need fetch API
  //   if (!isNeedFetchCourse) {
  //     setIsLoading(false);
  //     return;
  //   }
  //   // Fetch API
  //   if (!noMoreData) {
  //     fetchMyCourses();
  //   }
  // }, [tag, currentUser, page]);

  useEffect(() => {
    if (isPopular) {
      setCurrData(popularCourse);
    } else {
      if (data && data.length) {
        setCurrData(data);
        setTotalData(data?.length);
        setIsLoading(false);
      } else {
        fetchMyCourses();
        setIsLoading(false);
      }
    }
  }, [data]);

  // useEffect(() => {
  //   setPage(1);
  // }, [limit]);

  const showLess = displayedItems.length === totalData;

  const buttonHandler = (type: string) => {
    trackEvent({
      event: "b2b_homepage_course_more_a",
      property: {
        category_name: tag ?? t(title) ?? "",
        button_type: type,
        section,
      },
    });

    // if (noMoreData && showLess) {
    //   setLimit(3);
    //   setPage(1);
    //   return;
    // }
    // setPage((p) => p + 1);
  };

  const isDesktopView =
    typeof window !== "undefined" && window.screen.width >= 480;

  // useEffect(() => {
  //   /// Prevent data not passed in props
  //   if (currData.length === 0) {
  //     setNoMoreData(false);
  //   } else {
  //     setNoMoreData(currData.length >= totalData);
  //   }
  // }, [currData]);

  const settings = {
    className: "swiper-customized",
    freeMode: isDesktopView,
    spaceBetween: 10,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.2,
        slidesPerGroup: 1.2,
        spaceBetween: 10,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 10,
      },
      // when window width is >= 912px
      1200: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
      },
    },
  };

  const isMobile = typeof window !== "undefined" && window.innerWidth < 768;
  const router = useRouter();

  useEffect(() => {
    if (currData.length > 0) {
      const shuffled = [...currData].sort(() => Math.random() - 0.5);
      setShuffledData(shuffled);
    }
  }, [currData]);

  const showedItems = shuffledData?.slice(0, 3);

  return (
    <div className="mb-32 relative">
      {
        // isLoading ? (
        //   <SkeletonWrapper className="grid grid-cols-1">
        //     {[1].map((el) => (
        //       <SkeletonRect className="h-50 rounded-8 w-full" />
        //     ))}
        //   </SkeletonWrapper>
        // ) :

        currData && currData.length >= 1 ? (
          // <h2
          //   // onClick={() => buttonHandler("Section Title")}
          //   className="text-neutral-80 text-h4 font-medium mb-16 px-16 md:px-0"
          // >
          //   {tag == "newest"
          //     ? t(title)
          //     : tag
          //     ? tr(tag) ?? ""
          //     : data
          //     ? title ?? ""
          //     : tag ?? ""}
          // </h2>
          <div
            className={
              "flex items-center justify-between md:justify-start gap-8 pr-16 pb-20 pt-8 gap-20 xl-max:pb-12 xl-max:pt-8"
            }
          >
            <h2
              className={
                "text-neutral80_to_dark10 text-h4 font-medium " +
                (currData && currData.length > 3 ? "cursor-pointer" : "")
              }
              onClick={() => {
                if (currData && currData.length > 3) {
                  if (loginState == "LOGIN") {
                    if (!isMobile) {
                      setSelectedTitle(
                        tag == "newest"
                          ? t(title)
                          : data
                          ? title ?? ""
                          : tag
                          ? tr(tag) ?? ""
                          : tag
                      );
                      setIsShow(!isShow);
                      fetchSectionDetail();
                    } else {
                      const ids = !id
                        ? "newest"
                        : id !== "default"
                        ? id
                        : "default";

                      if (currData && currData.length > 3) {
                        (router as any).push({
                          pathname: "/section-detail/[id]",
                          query:
                            ids !== "default" && !isPopular
                              ? {
                                  id: ids,
                                  section: "custom",
                                }
                              : isPopular
                              ? {
                                  id: ids,
                                  section: "popular",
                                }
                              : {
                                  id: ids,
                                  section: "custom",
                                  tag: tag,
                                },
                        });
                      }
                    }
                  } else {
                    if (currData && currData.length > 3) {
                      (router as any).push({
                        pathname: "/login",
                      });
                    }
                  }
                }
              }}
            >
              {tag == "newest"
                ? t(title)
                : data
                ? title ?? ""
                : tag
                ? tr(tag) ?? ""
                : tag}
            </h2>

            {currData && currData.length > 3 && (
              <div
                className="flex gap-4 text-b2 font-medium cursor-pointer text-purple50_to_dark10"
                onClick={() => {
                  if (loginState == "LOGIN") {
                    if (!isMobile) {
                      setSelectedTitle(
                        tag == "newest"
                          ? t(title)
                          : tag
                          ? tr(tag) ?? ""
                          : data
                          ? title ?? ""
                          : tag ?? ""
                      );
                      setIsShow(!isShow);
                      fetchSectionDetail();
                    } else {
                      const ids = !id
                        ? "newest"
                        : id !== "default"
                        ? id
                        : "default";

                      (router as any).push({
                        pathname: "/section-detail/[id]",
                        query:
                          ids !== "default" && !isPopular
                            ? {
                                id: ids,
                                section: "custom",
                              }
                            : isPopular
                            ? {
                                id: ids,
                                section: "popular",
                              }
                            : {
                                id: ids,
                                section: "custom",
                                tag: tag,
                              },
                      });
                    }
                  } else {
                    (router as any).push({
                      pathname: "/login",
                    });
                  }
                }}
              >
                <span className="hidden md:block">{t("viewAll")}</span>
                <IconArrow isCaret={true} direction="right" color="#551FC1" />
              </div>
            )}
          </div>
        ) : null
      }

      {!getCourses.isLoading && currData && currData.length > 0 && (
        <>
          {isDesktopView ? (
            <Swiper
              {...settings}
              navigation={isDesktopView && currData.length > 4}
              onSlideNextTransitionStart={(_) => {
                buttonHandler("Next Courses");
              }}
              onSlidePrevTransitionStart={(_) => {
                buttonHandler("Previous Courses");
              }}
            >
              {title === "sectionNew" ||
              title === "Kursus Terdownload" ||
              isPopular
                ? currData.slice(0, 3).map((course, index) => (
                    <SwiperSlide key={"Swiper-" + course.slug}>
                      <Suspense fallback={<SkeletonCircle />}>
                        <CardCourseWithLesson
                          {...course}
                          tracker={{
                            event: "b2b_homepage_course_a",
                            property: {
                              course_name: course?.name,
                              course_slug: course?.slug,
                              institution_name: course?.institution?.name,
                              category_name: tag ?? t(title) ?? "",
                              section,
                              index,
                              is_mandatory_course: false,
                            },
                          }}
                        />
                      </Suspense>
                    </SwiperSlide>
                  ))
                : showedItems?.slice(0, 3).map((course, index) => (
                    <SwiperSlide key={"Swiper-" + course.slug}>
                      <Suspense fallback={<SkeletonCircle />}>
                        <CardCourseWithLesson
                          {...course}
                          tracker={{
                            event: "b2b_homepage_course_a",
                            property: {
                              course_name: course?.name,
                              course_slug: course?.slug,
                              institution_name: course?.institution?.name,
                              category_name: tag ?? t(title) ?? "",
                              section,
                              index,
                              is_mandatory_course: false,
                            },
                          }}
                        />
                      </Suspense>
                    </SwiperSlide>
                  ))}
            </Swiper>
          ) : (
            <div
              className={`${
                isHidden !== null ? "px-16" : "px-0"
              } md:px-0 flex gap-12 overflow-x-scroll `}
            >
              {title === "sectionNew" || isPopular
                ? currData.map((course, index) => (
                    <Suspense fallback={<SkeletonCircle />}>
                      <CardCourseWithLesson
                        {...course}
                        tracker={{
                          event: "b2b_homepage_course_a",
                          property: {
                            course_name: course?.name,
                            course_slug: course?.slug,
                            institution_name: course?.institution?.name,
                            category_name: tag ?? t(title) ?? "",
                            section,
                            index,
                            is_mandatory_course: false,
                          },
                        }}
                        wrapClass="w-[300px] md:w-full"
                      />
                    </Suspense>
                  ))
                : currData
                    .sort(() => Math.random() - 0.5)
                    .map((course, index) => (
                      <Suspense fallback={<SkeletonCircle />}>
                        <CardCourseWithLesson
                          {...course}
                          tracker={{
                            event: "b2b_homepage_course_a",
                            property: {
                              course_name: course?.name,
                              course_slug: course?.slug,
                              institution_name: course?.institution?.name,
                              category_name: tag ?? t(title) ?? "",
                              section,
                              index,
                              is_mandatory_course: false,
                            },
                          }}
                          wrapClass="w-[300px] md:w-full"
                        />
                      </Suspense>
                    ))}
            </div>
          )}
        </>
      )}

      {getCourses.isLoading && (
        <SkeletonWrapper className="grid grid-cols-3 xl-max:grid-cols-2 hg-max:!grid-cols-1 gap-20 my-30">
          {[1, 2, 3].map((el) => (
            <SkeletonRect key={el} className="h-300 rounded-8 w-full" />
          ))}
        </SkeletonWrapper>
      )}
    </div>
  );
};

export default Section;
