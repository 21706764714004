import { IconAngle } from "components/Icon/IconAngle";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useSystemStore } from "components/PageHeader/systemStore";

type Props = {
  useNavigation?: boolean;
  className?: string;
  children: ReactNode;
  paddingHorizontal?: number;
  top?: number;
};

export const HorizontalScroll: React.FC<Props> = ({
  className = "",
  paddingHorizontal = 8,
  children,
  useNavigation = true,
  top = 0,
}) => {
  const [theme] = useSystemStore((state) => [state.theme]);
  const isMobileView = typeof window !== "undefined" && window.innerWidth < 768;

  const contentScrollRef = useRef<HTMLDivElement>(null);

  const [currentScrollLeft, setCurrentScrollLeft] = useState(0);
  const [currentScrollWidth, setCurrentScrollWidth] = useState(0);
  const [currentContainerWidth, setCurrentContainerWidth] = useState(0);

  useEffect(() => {
    if (contentScrollRef.current) {
      setCurrentScrollLeft(contentScrollRef.current.scrollLeft);
      setCurrentScrollWidth(contentScrollRef.current.scrollWidth);
      setCurrentContainerWidth(contentScrollRef.current.clientWidth);

      contentScrollRef.current.addEventListener("scroll", () => {
        if (contentScrollRef.current) {
          setCurrentScrollLeft(contentScrollRef.current.scrollLeft);
        }
      });
    }
  }, []);

  return (
    <div className="relative">
      <div
        ref={contentScrollRef}
        className={`${
          !isMobileView &&
          `flex overflow-x-auto hide-scrollbar ${
            currentScrollLeft > 0 && "ml-50"
          } ${
            currentScrollLeft < currentScrollWidth - currentContainerWidth &&
            "mr-44"
          }`
        } ${className}`}
      >
        <div className={`flex items-center gap-0`}>
          <div
            className="md:hidden block"
            style={{ minWidth: paddingHorizontal, width: paddingHorizontal }}
          />
          {children}
          <div
            className="md:hidden block"
            style={{ minWidth: paddingHorizontal, width: paddingHorizontal }}
          />
        </div>
      </div>
      {useNavigation && currentScrollLeft > 0 && (
        <div
          className="absolute -top-2 z-10"
          onClick={() => {
            if (contentScrollRef.current) {
              contentScrollRef.current.scrollBy({
                left: -336,
                behavior: "smooth",
              });
            }
          }}
        >
          <div
            className="p-2 rounded-full absolute bg-purple05_to_dark20 flex justify-start items-center cursor-pointer -left-1"
            style={{ top: 10 }}
          >
            <div className="rounded-full border border-purple05_to_dark20 w-36 h-36 flex justify-center items-center">
              <IconAngle color={theme === "dark" ? "#FFFFFF" : "#3E3851"} />
            </div>
          </div>
        </div>
      )}
      {useNavigation &&
        currentScrollLeft < currentScrollWidth - currentContainerWidth && (
          <div
            className="absolute -top-2 right-32 z-10"
            onClick={() => {
              if (contentScrollRef.current) {
                contentScrollRef.current.scrollBy({
                  left: 336,
                  behavior: "smooth",
                });
              }
            }}
          >
            <div
              className="p-2 rounded-full absolute bg-purple05_to_dark20 flex justify-end items-center cursor-pointer"
              style={{ top: 10 }}
            >
              <div className="rounded-full border border-purple05_to_dark20 w-36 h-36 flex justify-center items-center">
                <IconAngle
                  direction="right"
                  color={theme === "dark" ? "#FFFFFF" : "#3E3851"}
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
