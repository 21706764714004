import type { UserData } from "../modules/Auth/authStore";

const toggleView = (currentUser: UserData) => {
  const token = window?.localStorage?.getItem("token");

  if (token) {
    // console.log("start tawk to ");
    // @ts-ignore
    window.Tawk_API.showWidget();
    // @ts-ignore
    window.Tawk_API.start();
  } else {
    // console.log("shutdowning tawk to ");
    // @ts-ignore
    if (window.Tawk_API && typeof window.Tawk_API.shutdown === 'function') {
      // console.log("==shutdown ok");
      // @ts-ignore
      window.Tawk_API.shutdown();
      // @ts-ignore
      window.Tawk_API.hideWidget();
    }
  }
};

export const checkTawkTo = (currentUser: UserData) => {
  // Ensure the Tawk object has initalized
  // @ts-ignore
  if (window.$_Tawk && window.$_Tawk.init) {
    toggleView(currentUser);
  } else {
    // If the Tawk object didn't initilize, use a differnt method of loading
    // @ts-ignore
    if (window.Tawk_API) {
      // @ts-ignore
      window.Tawk_API.onLoad = function () {
        toggleView(currentUser);
      };
    }
  }
};
