import createStore from "zustand";

export type SectionStore = {
  // state
  title: string;
  isShow: boolean;
  isLoading: boolean;
  data: any[];

  // actions
  setSelectedTitle: (item: string) => void;
  setIsShow: (item: boolean) => void;
  setDataSection: (item: any[]) => void;
  setLoading: (item: boolean) => void;
};

export const useModalSectionStore = createStore<SectionStore>((set) => ({
  // state
  title: "",
  isShow: false,
  isLoading: true,
  data: [],

  // actions
  setSelectedTitle(item) {
    set({ title: item });
  },
  setIsShow(item) {
    set({ isShow: item });
  },
  setDataSection(item) {
    set({ data: item });
  },
  setLoading(item) {
    set({ isLoading: item });
  },
}));
